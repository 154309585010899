
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import { onBeforeMount, ref, defineComponent, inject, Ref } from "vue";
import { apiGet, apiPost } from "@/api";
import { useRoute } from "vue-router";
import { Toast } from "@/utils/notify";
import Swal from "sweetalert2";
import InputWrapper from "@/components/InputWrapper.vue";
import DefaultLoader from "@/components/loader/DefaultLoader.vue";
import SecondaryModal from "@/components/modal/SecondaryModal.vue";
export default defineComponent({
  name: "SellGiftCardDetail",
  components: {
    DefaultLoader,
    SecondaryModal,
    InputWrapper,
    HourglassLoader,
  },
  setup() {
    const loading = ref(false);
    const declineModal = ref(false);
    const declineModalConfirm = ref(false);
    const sureConfirmDecline = ref(false);
    const declineComment = ref("");
    const customComment = ref(false);
    const automatedResponses = [
      {
        id: 1,
        comment: "Numbers not showing full",
      },
      {
        id: 2,
        comment: "Card has been used, kindly contact your source",
      },
      {
        id: 3,
        comment: "Card numbers not clear",
      },
      {
        id: 4,
        comment: "Repeated trade, Card has been used by another customer",
      },
      {
        id: 5,
        comment: "Card is badly scratched, some codes are peeled off",
      },
      {
        id: 6,
        comment: "We do not accept this kinds of Card, thanks",
      },
    ];

    const canvasClose: Ref<HTMLButtonElement | null> = ref(null);
    const data: Ref<Record<string, any> | null> = ref(null);

    const RequestConfirmation = inject("RequestConfirmation");

    const approvalData: Ref<{
      amount: null | number;
      remark: string;
    }> = ref({
      amount: null,
      remark: "Approved Successfully",
    });

    const route = useRoute();

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `admin/coin/inward/transactions/${route.params.sellCoinId}`
        );
        data.value = response.data.data.transaction;
        approvalData.value.amount =
          Number(response.data.data.transaction.amount) *
          Number(response.data.data.transaction.rate) *
          Number(response.data.data.transaction.CreditCurrency.we_buy);
        approvalData.value.remark = response.data.data.transaction.remark;
        Toast.fire({
          icon: "success",
          title: "Transaction Detail Fetched Successfully",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    const ApproveTransaction = async () => {
      loading.value = true;
      const amount =
        Number(approvalData.value.amount) /
        Number(data.value?.rate) /
        Number(data.value?.CreditCurrency.we_buy);
      try {
        await apiPost(
          `/admin/cards/sell/transactions/${route.params.transactionId}/approve`,
          { ...approvalData.value, amount }
        );
        Toast.fire({
          icon: "success",
          title: "Transaction Approved Successfully",
        }).then();
        canvasClose.value?.click();
        await RefreshData();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to approve: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    // add Decline options
    // const addOption = (arr: string[]): string => {
    //   let optionItems = "";
    //   // const optionWrapper = () => {
    //   //   return `<div class="declineOptionWrapper">

    //   //     </div>`;
    //   // };
    //   arr.forEach((item) => {
    //     optionItems += `<button class="d-block my-2 mx-auto" value="${item}">${item}</button>`;
    //   });
    //   return optionItems;
    // };

    // title: "Decline Transaction",
    //     inputLabel: "Enter Reason/Comment for declining",
    //     html: addOption([
    //       "invalid image",
    //       "invalid amount",
    //       "invalid card",
    //       "other",
    //     ]),

    const DeclineTransaction = async (comment: string) => {
      // const { value: text } = await Swal.fire({

      //   input: "textarea",
      //   inputLabel: "Enter Reason/Comment for declining",
      //   inputPlaceholder: "Type your reason/comment here...",
      //   inputAttributes: {
      //     "aria-label": "Type your reason/comment here",
      //   },
      //   showCancelButton: true,
      // });

      // if (!text) {
      //   return;
      // }

      // if (!declineComment.value) {
      //   return;
      // }

      loading.value = true;
      try {
        await apiPost(
          `/admin/cards/sell/transactions/${route.params.transactionId}/decline`,
          { comments: comment }
        );
        sureConfirmDecline.value = false;
        Toast.fire({
          icon: "success",
          title: "Transaction Declined Successfully",
        }).then();

        await RefreshData();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to decline: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    onBeforeMount(RefreshData);

    return {
      RefreshData,
      ApproveTransaction,
      DeclineTransaction,

      automatedResponses,
      RequestConfirmation,
      customComment,
      approvalData,
      route,
      data,
      loading,
      declineModal,
      declineModalConfirm,
      sureConfirmDecline,
      declineComment,
      canvasClose,
    };
  },
});
